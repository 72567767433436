import React from 'react';
import {Row} from 'reactstrap';

import './authors.scss';

function Authors() {
  return (
    <Row>
      <div className="col-xs-12 col-lg-3">
        &nbsp;
      </div>
      <div className="col-xs-12 col-lg-9">
        <div className="authors-wrapper">
          <p>They include:</p>
          <ul className="list-unstyled">
            <li>Dorian Abbot (University of Chicago, US)</li>
            <li>Andreas Bikfalvi (University of Bordeaux, France)</li>
            <li>April Bleske-Rechek (University of Wisconsin–Eau Claire, US)</li>
            <li>Sir Walter Bodmer (University of Oxford, UK)</li>
            <li>Peter Boghossian (University of Austin, US)</li>
            <li>Carlos Carvalho (University of Texas–Austin, US)</li>
            <li>Joseph Ciccolini (Aix Marseille University, France)</li>
            <li>Jerry Coyne (University of Chicago, US)</li>
            <li>Jürgen Gauss (University of Mainz, Germany)</li>
            <li>Peter Gill (University of Sidney, Australia)</li>
            <li>Svetlana Jitomirskaya (University of California–Irvine, US)</li>
            <li>Lee Jussim (Rutgers University, US)</li>
            <li>Anna Krylov (University of Southern California, US)</li>
            <li>Glenn Loury (Brown University, US)</li>
            <li>Patricia Nayna Schwerdtle (Heidelberg University, Germany)</li>
            <li>Luana Maroja (Williams College, US)</li>
            <li>John McWhorter (Columbia University, US)</li>
            <li>Sadredin Moosavi (Rochester Community Technical College, US)</li>
            <li>Judea Pearl (University of California–Los Angeles, US)</li>
            <li>Marisol Quintanilla-Tornel (Michigan State University, US)</li>
            <li>H. Fritz Schaefer III (University of Georgia, US)</li>
            <li>Peter Schreiner (Justus Liebig University, Germany)</li>
            <li>Peter Schwerdtfeger (Massey University, New Zealand)</li>
            <li>Dan Shechtman (Technion, Israel)</li>
            <li>Mikhaill Shifman (University of Minnesota, US)</li>
            <li>Jay Tanzman (Independent, US)</li>
            <li>Bernhardt Trout (MIT, US)</li>
            <li>Arieh Warshel (University of Southern California, US)</li>
            <li>James West (Vanderbilt University, US)</li>
          </ul>
        </div>
      </div>
    </Row>
  );
}

export default Authors;
