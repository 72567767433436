import React from 'react';
import ShareLinks from '../ShareLinks/share';

import './petition.scss';

function Petition() {
  return (
    <div className="row">
      <div className="col">
        <div className="petition-wrapper">
          <h2>Press release</h2>
          <div className="note-wrapper mb-3 font-weight-bold">April 28, 2023</div>
          <div className="note-wrapper mb-3 font-weight-bold">Contact: <a href="mailto:press@indefenseofmerit.org">press@indefenseofmerit.org</a></div>
          <div className="row">
            <div className="col-xs-12 col-lg-3">
              <div className="d-none d-lg-block">
                <ShareLinks/>
              </div>
            </div>
            <div className="col-xs-12 col-lg-9">
              <div className="d-block d-lg-none">
                <ShareLinks/>
              </div>
              <p>
                Today, an interdisciplinary group of 29 distinguished scientists, including two Nobel Laureates, has published a first-of-its-kind, peer-reviewed paper shedding light on the ideological attack on science taking place in universities, publishing houses, and funding agencies. <a href="https://www.wsj.com/articles/the-hurtful-idea-of-scientific-merit-controversy-nih-energy-research-f122f74d" target="_blank" rel="noopener noreferrer">In an op-ed in the <i>Wall Street Journal</i></a> (non-paywalled version is available <a href="https://whyevolutionistrue.com/2023/06/02/our-wall-street-journal-op-ed-free-at-last/" target="_blank" rel="noopener noreferrer">here</a>), two of the authors explain why the idea that scientific theories should be judged on merit rather than identity politics has become unpalatable–so unpalatable that it ended up in the <i>Journal of Controversial Ideas</i>.
              </p>

              <p>
                The paper describes the current clash in science between classical liberal values, such as merit, and a new postmodern worldview that purports that reality is subjective and can only be understood through a scientist’s "lived experience," which in turn depends on gender, ethnicity, and other immutable characteristics. This ideology is enforced by "Diversity, Equity, and Inclusion" (DEI) officers and bureaucracies, which have become entrenched throughout academia. Because science is based on the search for objective truths, the ideology threatens the entire scientific enterprise.
              </p>

              <p>
                The  preprint is <a href="https://iopenshell.usc.edu/pubs/pdf/JCI_Merit_final.pdf" target="_blank" rel="noopener noreferrer">available here</a> and the full paper will appear <a href="https://journalofcontroversialideas.org/article/3/1/236" target="_blank" rel="noopener noreferrer">here</a> on the journal website.
              </p>


              <h3>What does the paper include?</h3>

              <p>
                The paper establishes, first, how merit is one of the pillars of classical liberalism, humanism, and democracy. Built on merit, science has proven effective in producing technological advances, reducing human suffering, and improving the quality of life globally.
              </p>

              <p>
                The paper documents the ongoing attempt to undermine the core principles of classical liberalism and replace merit in science with an identitarian ideology, Critical Social Justice (CSJ).
              </p>

              <p>
                The authors explain the philosophical origins of the conflict, document the intrusion of the ideology into scientific institutions, discuss the perils of abandoning merit, and offer an alternative, humanistic approach to address social inequality and injustice.
              </p>

              <p>
                The paper, meticulously documented, provides historic evidence of merit-based science being effective and fair, illustrates the perils of subjugating science to ideological control, and illuminates the current attack on merit and science.
              </p>

              <h3>What is the goal of the paper?</h3>

              <p>
                <strong>Anna Krylov</strong>, Professor of Chemistry at the University of Southern California, elaborates, "Our goal is to reach the broad, interdisciplinary academic community, especially STEMM [science, technology, engineering, mathematics, and medicine]. It is our experience that people in STEMM are less aware of the nature and source of the current attacks on merit than are social scientists. Many do not see what is happening right under their noses. They don’t understand the extent to which illiberal ideology has replaced merit already."
              </p>

              <p>
                Krylov explains that the extensive list of examples the authors have compiled of the infusion of identity politics into science is necessary to counteract how CSJ proponents have underplayed and obfuscated the extent of their influence.
              </p>

              <p>
                When confronted with seemingly outrageous examples of this ideological intrusion — such as the banning of common English words, the introduction of gender and race criteria for citations in academic papers or selection of the reviewers, the elimination of candidates for faculty positions for stating that they would treat their students without regard for their race or gender—the reaction is often "those cases must be outliers" or "you're exaggerating, that's not real DEI."
              </p>

              <p>
                Krylov hopes that the extensive exhibits in the paper will convince the scientific community that, in fact, this is precisely what DEI is doing. She asks, for example, “Which of the 67 papers in the Journal of Chemical Education’s special issue on DEI, comprising such topics as the decolonization of the chemistry curriculum, chemistry and racism, and gender and sexual orientation identities in the chemistry classroom, is the outlier?”
              </p>

              <p>
                Other prominent co-authors explained their own motivations for writing the paper.
              </p>

              <p>
                The Nobel Laureate <strong>Arieh Warshel</strong> said, “My concern is that a part of the scientific establishment is promoting the wrong direction in science. Abandoning merit will make it impossible for America to compete in an increasingly technological world. I decided to participate in this paper to help stop this growing danger to American science.”
              </p>

              <p>
                <strong>Sir Walter Bodmer</strong>, professor of genetics and oncology at the University of Oxford, wrote, “I am very concerned about the rapidly increasing insidious effects of dogmatic postmodernist attitudes against facts and logical arguments even in highly respected academic institutions and scientific journals. These, for example, question the need to take into account ethnic genetic origins for medical reasons and even the biological definition of sex.”
              </p>

              <p>
                Bodmer continued, “Such attitudes threaten the basis of scientific advances on which our current and future prosperity depends, which in turn depend on the recognition of unbiased merit both in science and of scientists. I hope that our paper will be a wake-up call, especially to the scientific community, of the dangers of this increasingly widespread insidious acceptance of postmodernist beliefs.”
              </p>

              <h3>Why was the paper published in the <i>Journal of Controversial Ideas</i>?</h3>

              <p>
                As <a href="https://www.wsj.com/articles/the-hurtful-idea-of-scientific-merit-controversy-nih-energy-research-f122f74d" target="_blank" rel="noopener noreferrer">the <i>Wall Street Journal</i> op-ed</a> explains, the authors attempted to publish their paper in the Proceedings of the National Academy of Sciences (PNAS) as a counterpoint to several perspectives the journal had published. They were advised to remove the word “merit” from the title because, according to the journal’s editorial board, “This concept of merit, as the authors surely know, has been widely and legitimately attacked as hollow….”
              </p>

              <p>
                This prompted the authors to explain in the paper, “not only is meritocracy in science a controversial idea, in some circles the very existence of merit as a concept is questioned.” “Ultimately,” says Krylov, “PNAS rejected the paper on grounds that appeared to be largely ideological.”
              </p>

              <p>
                After several more unsuccessful attempts to interest scientific journals in the manuscript, the authors approached the <i>Journal of Controversial Ideas</i>, where it was published after undergoing vigorous double-blind peer review. The journal was founded by Peter Singer and two other moral philosophers whose aim is to promote “free inquiry on controversial topics.”
              </p>

              <p>
                Commenting on publishing in the <i>Journal of Controversial Ideas</i>, co-author and professor of mathematics, <strong>Svetlana Jitomirskaya</strong>, says: “To me it feels quite absurd that we even had to write this paper, not to mention that it had to be published in the Journal of Controversial Ideas. Isn’t it self-evident that science should be based on merit? I thought that no scientist took arguments to the contrary seriously. I was shocked by the reasons PNAS rejected our paper. The reviewers, all presumably distinguished scientists, were clearly in favor of the opposing arguments.”
              </p>

              <h3>About the authors</h3>

              <p>
                The paper is authored by an interdisciplinary team of scientists and public intellectuals from a diverse array of fields, including biology, medicine, physics, chemistry, computer science, mathematics, statistics, engineering, psychology, philosophy, finance, economics, and American studies.
              </p>

              <p>
                The authors work in seven countries: the United States, the United Kingdom, France, Germany, Australia, New Zealand, and Israel.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Petition;
