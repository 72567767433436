import React from 'react';
import { Row, Col } from 'reactstrap';

import './header.scss';

function Header() {
  return (
    <Row>
      <Col>
        <div className="header-wrapper">
            &nbsp;
          <div className="title-wrapper">
            <h1>Scientists Defend Merit in Science</h1>
            <div className="intro">
              For first time, scientists organize to push back against identity politics
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default Header;
