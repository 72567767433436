import './App.scss';
import Header from "./components/Header/header";

import { Container } from 'reactstrap';
import Petition from "./components/Petition/petition";
import Authors from "./components/Authors/authors";
import Reactions from "./components/Reactions/reactions";
import Quotes from "./components/Quotes/quotes";

function App() {
  return (
    <div className="App">
      <Container>
        <Header />
        <Petition />
        <Authors />
        <Reactions />
        <Quotes />
      </Container>
    </div>
  );
}

export default App;
