import React from 'react';
import {Row} from 'reactstrap';

function Quotes() {
  return (
    <Row>
      <div className="col-xs-12 col-lg-3">
        &nbsp;
      </div>
      <div className="col-xs-12 col-lg-9">
        <div className="reactions-wrapper">
          <h2>What They Are Saying About “In Defense of Merit in Science”</h2>
          <p>“It says a lot about our current moment that a paper defending merit in science has just been published in the Journal of Controversial Ideas. … It pushes back against the increasingly popular idea of judging scientific theories on identity politics and the ‘lived experience’ of the scientist, as opposed to the actual science.” <strong>Bari Weiss</strong>, <a href="https://www.thefp.com/p/tgif-why-tucker-carlson-got-fired" target="_blank" rel="noopener noreferrer">The Free Press</a>, 4.28.23</p>

          <p>“One needn’t agree with every aspect of the authors’ politics or with all of their solutions. But to ignore or dismiss their research rather than impartially weigh the evidence would be a mistake. We need, in other words, to judge the paper on the merits. That, after all, is how science works.” – <strong>Pamela Paul</strong>, <a href="https://www.nytimes.com/2023/05/04/opinion/science-evidence-merits.html" target="_blank" rel="noopener noreferrer">The New York Times</a>, 5.4.23</p>

          <p>“Preeminent scientists are sounding the alarm that ideology is undermining merit in the sciences. I strongly support them.” – <strong>Richard Dawkins</strong>, <a href="https://twitter.com/RichardDawkins/status/1651970327902138370" target="_blank" rel="noopener noreferrer">Twitter</a>, 4.28.23</p>

          <p>“The fact that such a paper even had to be written (let alone that it is regarded as controversial) shows clearly just what sort of trouble we're in. STEM specialists: wake the hell up. Your endeavour is in real danger.” – <strong>Dr. Jordan Peterson</strong>, <a href="https://twitter.com/jordanbpeterson/status/1652373223026073603" target="_blank" rel="noopener noreferrer">Twitter</a>, 4.28.23</p>

          <p>“A *Must Read Paper* on the importance of merit over ideology in science. A claim now seen as controversial by our leading journals and editors, so published in the Journal of Controversial ideas.” – <strong>Jeffrey Flier</strong>, <a href="https://twitter.com/jflier/status/1651998353738149901" target="_blank" rel="noopener noreferrer">Twitter</a>, 4.28.23</p>

          <p>“An important paper by 29 distinguished scholars (including two Nobelists) defending merit in science. (Are you surprised that it's in the Journal of Controversial Ideas?)” – <strong>Jonathan Rauch</strong>, <a href="https://twitter.com/jon_rauch/status/1651779859897798656" target="_blank" rel="noopener noreferrer">Twitter</a>, 4.27.23</p>

          <p>“I hope that the authors do realize that the mass majority support your stance. I have talked with many of them at colleges, universities, conferences, etc. But they are afraid of being ratioed, reprimanded, fired, and abused.” – <strong>Jesse Sabatini</strong>, <a href="https://twitter.com/JJSabatini/status/1653061668337795073" target="_blank" rel="noopener noreferrer">Twitter</a>, 5.1.23</p>

          <p>“The ideology described in the paper, whereby theories are judged less on merit and more on identity politics, is enforced by ‘Diversion, Equity, and Inclusion’ (DEI) officers and bureaucracies, which have become entrenched throughout academia.” – <strong>The Academic Freedom Alliance</strong>, <a href="https://academicfreedom.org/april-2023/" target="_blank" rel="noopener noreferrer">Newsletter</a>, 4.28.23</p>

          <p>“An alarming clash between Classical Liberal epistemology & identity-based ideologies is endangering science, a key driver to human progress & improved environmental outcomes. Scientific institutions & Universities must re-embrace Liberal epistemology.” <strong>David Bertioli</strong>, <a href="https://twitter.com/BotanyBert/status/1651997184760459303" target="_blank" rel="noopener noreferrer">Twitter</a>, 4.27.23</p>

          <p>“A very thorough article with quite a lot of depth to it. It is shameful that just because a journal (PNAS) finds the concept of merit ‘hollow,’ it refuses to publish a paper that rebuts that. That contradicts the purpose of science.” – <strong>ScioSophia</strong>, <a href="https://www.youtube.com/watch?v=6WYj5i2DIBo" target="_blank" rel="noopener noreferrer">YouTube</a>, 5.2.23</p>

          <p>“An important new paper… Simply put, science is not subjective. For it to work, it CAN'T be.” – <strong>Center for Inquiry</strong>, <a href="https://twitter.com/center4inquiry" target="_blank" rel="noopener noreferrer">Twitter</a>, 5.4.23</p>

          <p>“A paper defending merit in science, just published in the Journal of Controversial Ideas and with two Nobel laureates among the authors, was rejected from another journal on grounds of ‘hurtfulness.’” – <strong>Noah Carl</strong>, <a href="https://twitter.com/NoahCarl90/status/1651943237437800456" target="_blank" rel="noopener noreferrer">Twitter</a>, 4.28.23</p>

          <p>“Astonishing that 'merit in science' needs defending in this day and age.  Here is a very good defense.” – <strong>Judith Curry</strong>, <a href="https://twitter.com/curryja/status/1651954572687208449" target="_blank" rel="noopener noreferrer">Twitter</a>, 4.28.23</p>

          <p>“Hostility towards the paper shows that social justice ideologies continue to dominate.” – <strong>The College Fix</strong>, <a href="https://twitter.com/CollegeFix/status/1653580136313425921" target="_blank" rel="noopener noreferrer">Twitter</a>, 5.2.23</p>

          <p>"A sad confirmation of their point: Scientists Defend Merit in Science in a paper that could be published only in the Journal of Controversial Ideas." - <strong>Steven Pinker</strong>, <a href="https://twitter.com/sapinker/status/1654902073153196034" target="_blank" rel="noopener noreferrer">Twitter</a>, 05.06.23</p>

          <p>"We have to accept to be tarred if we are centrist Liberals that support an open and pluralistic system. Sigh. - I wish I would have been more bold and a co-author of "in defense of merit in science", but I was coerced like so many to put my head down. Liberty, Merit, Dignity." - <strong>Harald Ade</strong>, <a href="https://twitter.com/HaraldAde/status/1660266266194595840" target="_blank" rel="noopener noreferrer">Twitter</a>, 05.21.23</p>
        </div>
      </div>
    </Row>
  );
}

export default Quotes;
